<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const props = defineProps({
	onShowDetail: {
		type: Function,
		required: true
	}
})

const store = useStore()
const user = computed(() => store.state.user)

// 获取套餐等级别名
const getLevelAlias = (level) => {
	const alias = { 1: '基础套餐', 2: '进阶套餐', 3: '专业套餐' }
	return alias[level] || '异常错误'
}

// 格式化流量数据
const formatTraffic = (bytes) => {
	return Number(bytes / 1024 / 1024 / 1024).toFixed(0)
}

// 格式化日期
const formatDate = (date) => {
	return new Date(date).toLocaleDateString('zh-CN')
}
</script>

<template>
	<div class="card">
		<div class="card-header p-3 pb-0">
			<div class="d-flex justify-content-between align-items-center">
				<h6 class="mb-0">当前套餐</h6>
				<button 
					class="btn btn-outline-primary btn-sm px-3 mb-0"
					@click="onShowDetail"
				>
					<i class="fas fa-list-ul me-2"></i>
					查看全部
				</button>
			</div>
		</div>
		
		<!-- 有效套餐视图 -->
		<div class="card-body p-3" v-if="user?.active_plan">
			<div class="row align-items-center">
				<div class="col-lg-4">
					<h5 class="mb-1">{{ getLevelAlias(user.active_plan.level) }}</h5>
					<p class="text-sm text-muted mb-0">
						有效期至: {{ formatDate(user.active_plan.expired_at) }}
					</p>
				</div>
				<div class="col-lg-8">
					<div class="d-flex align-items-center justify-content-lg-end">
						<div class="text-center me-5">
							<h6 class="mb-1">{{ formatTraffic(user.active_plan.remain) }}GB</h6>
							<p class="text-xs text-muted mb-0">剩余流量</p>
						</div>
						<div class="text-center">
							<h6 class="mb-1">{{ user.active_plan.total }}GB</h6>
							<p class="text-xs text-muted mb-0">总流量</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 无套餐视图 -->
		<div class="card-body p-3" v-else>
			<div class="text-center py-4">
				<h6 class="text-warning mb-2">当前无可用套餐</h6>
				<p class="text-secondary text-sm mb-3">
					账户当前没有任何可用的套餐，请购买新套餐以继续使用服务。
				</p>
			</div>
		</div>
	</div>
</template> 