<script setup>
    import { ref, onMounted } from 'vue';
    import { useRouter }    from 'vue-router';
    import { useStore }     from 'vuex';
    import { useShowToast } from '@/composables/useShowToast';
    import { useApiURL }    from '@/composables/useApiURL';
    import { useBase64 }    from '@/composables/useBase64';
    import axios            from '@/Plugins/axios';
    
    const store  = useStore();
    const router = useRouter();
    const { showToast } = useShowToast();
    const { getApiURL } = useApiURL();
    const { decodeUrlSafe, encodeUrlSafe } = useBase64();

    const props = defineProps({
        preEmail: {
            type: String,
            default: '',
        },
        prePassword: {
            type: String,
            default: '',
        },
    });

    const email    = ref('');
    const password = ref('');
    const totpCode = ref('');
    const needTotp = ref(false);
    const ready    = ref(false);
    const isLoading = ref(false);
    const isPasskeySupported = ref(false);

    onMounted(() => {
        if (store.state.token !== null) {
            router.push({ name: '用户中心' });
            return;
        }

        // 检查浏览器是否支持 Passkey
        isPasskeySupported.value = window.PublicKeyCredential && 
            window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable;

        // if pre* not empty, set to date
        if (props.preEmail !== '') {
            email.value = props.preEmail;
        }

        if (props.prePassword !== '') {
            password.value = props.prePassword;
        }
    });

    const login = async () => {
        if (email.value === '' || password.value === '') {
            showToast('错误', '请填写邮箱和密码', 'error');
            setTimeout(() => { isLoading.value = false; }, 450);
            return;
        }

        if (needTotp.value && totpCode.value.length !== 6) {
            showToast('错误', '请填写6位验证码', 'error');
            setTimeout(() => { isLoading.value = false; }, 450);
            return;
        }

        isLoading.value = true;
        
        try {
            const res = await axios.post(getApiURL('login'), {
                email:      email.value,
                password:   password.value,
                code:       totpCode.value,
            })

            if (res.data?.needTwoFactor === true) {
                needTotp.value  = true;
                isLoading.value = false;
                showToast('请注意', '需要进行二步验证', 'info');
                return;
            }

            ready.value = true;
            store.commit('setToken', res.data.token);

            // * Google Analytics
            if (typeof gtag === 'function') gtag('event', 'login', { 'method': 'password' });
        } catch(err) {
            console.error(err);
            isLoading.value = false;
        } finally {
            // 不解锁 isLoading：阻止用户重复点击
            if (ready.value === true) {
                setTimeout(() => { router.push({ name: '用户中心' }); }, 3000);
            }
        }
    }

    const loginWithPasskey = async () => {
        if (!isPasskeySupported.value) {
            showToast('错误', '您的浏览器不支持 Passkey', 'error');
            return;
        }

        isLoading.value = true;
        
        try {
            // 获取认证选项
            const res = await axios.get(getApiURL('passkey.login'), {
                params: {
                    email: email.value
                }
            });

            const options = res.data;

            // 格式转换为 WebAuthn 可识别格式
            try {
                options.challenge = decodeUrlSafe(options.challenge).buffer;
                
                if (options.allowCredentials) {
                    options.allowCredentials = options.allowCredentials.map(cred => {
                        return {
                            ...cred,
                            id: decodeUrlSafe(cred.id).buffer
                        };
                    });
                }
            } catch (e) {
                console.error('Data conversion error:', e);
                showToast('错误', '数据格式错误', 'error');
                return;
            }

            // 请求认证
            const credential = await navigator.credentials.get({
                publicKey: options
            });

            // 发送认证结果
            const authRes = await axios.post(getApiURL('passkey.login'), {
                email:      email.value,
                id:         credential.id,
                type:       credential.type,
                rawId:      encodeUrlSafe(credential.rawId),
                response: {
                    authenticatorData:  encodeUrlSafe(credential.response.authenticatorData),
                    clientDataJSON:     encodeUrlSafe(credential.response.clientDataJSON),
                    signature:          encodeUrlSafe(credential.response.signature),
                    userHandle:         credential.response.userHandle ? encodeUrlSafe(credential.response.userHandle) : null,
                },
            });

            ready.value = true;
            store.commit('setToken', authRes.data.token);

            // * Google Analytics
            if (typeof gtag === 'function') gtag('event', 'login', { 'method': 'passkey' });
        } catch(err) {
            console.error(err);
            if (err.name === 'NotAllowedError') {
                showToast('错误', '认证被取消', 'error');
            }

            isLoading.value = false;
        } finally {
            if (ready.value === true) {
                setTimeout(() => { router.push({ name: '用户中心' }); }, 3000);
            }
        }
    }
</script>

<template>
    <div class="card border-0 mb-0 authCard">
        <div class="card-header bg-transparent text-center">
            <div v-if="!needTotp">
                <h5 class="text-dark text-center mt-2 mb-3">登录账号</h5>
                <span class="mb-0 text-sm"></span>
            </div>

            <div v-else>
                <h5 class="text-dark text-center mt-2 mb-3">二步验证</h5>
                <span class="mb-0 text-sm">请输入六位数字验证码</span>
            </div>
        </div>

        <div class="card-body px-lg-5 py-0">
            <div class="row" v-if="!needTotp">
                <div class="col-12">
                    <!-- <button v-if="isPasskeySupported" type="button" class="btn btn-outline-primary w-100 mb-2" :disabled="isLoading" @click="loginWithPasskey">
                        <i class="fas fa-key me-2"></i>
                        使用通行密钥登录
                    </button> -->

                    <div class="text-center text-dark my-3">
                        <small class="fw-bold">或直接使用邮箱登录</small>
                    </div>

                    <form role="form" class="text-start">
                        <div class="mb-3">
                            <input type="email" class="form-control" placeholder="邮箱" aria-label="Email" autocomplete="off" v-model="email">
                        </div>

                        <div class="mb-3">
                            <input type="password" class="form-control" placeholder="密码" aria-label="Password" autocomplete="off" v-model="password">
                        </div>
                        
                        <div class="text-center">
                            <button type="button" class="btn btn-primary w-100 my-3 mb-2" :disabled="isLoading" @click="login">
                                <span v-if="isLoading" class="spinner-border spinner-border-sm me-2"></span>
                                登录
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <div class="row" v-else>
                <div class="form-group">
                    <label>TOTP验证码输入</label>
                    <input type="text" class="form-control" placeholder="请在此处输入6位验证码" autocomplete="off" v-model="totpCode">
                </div>

                <button class="btn btn-warning mb-0 mt-3 w-100" @click="login" :disabled="isLoading" v-if="!ready">
                    <span v-if="isLoading" class="spinner-border spinner-border-sm me-2"></span>
                    继续登录
                </button>

                <button type="button" class="btn btn-success w-100 my-3 mb-2 cursor-not-allowed" v-else>
                    <i class="fas fa-check-circle me-2"></i>
                    登录成功
                </button>
            </div>
        </div>

        <div class="card-footer px-lg-5 pt-0">
            <div class="d-flex justify-content-between">
                <p class="text-sm text-white mt-3 mb-0">还没有账号?
                    <a class="text-dark font-weight-bolder ps-2 custom-button cursor-pointer" @click="$emit('back', 'register')">
                        点击注册
                    </a>
                </p>

                <p class="text-sm text-white mt-3 mb-0">
                    <a class="text-dark font-weight-bolder ps-2 custom-button cursor-pointer" @click="$emit('back', 'reset')">忘记密码?</a>
                </p>
            </div>
        </div>
    </div>
</template>