<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore }     from 'vuex'

import axios            from '@/Plugins/axios'

import newModal         from '@/components/newModal.vue'

import { useShowToast } from '@/composables/useShowToast'
import { useApiURL }    from '@/composables/useApiURL'
import { useBase64 }    from '@/composables/useBase64'
import { useUserApi }   from '@/composables/useUserApi'

const store                 = useStore()
const { showToast }         = useShowToast()
const { getApiURL }         = useApiURL()
const { verifyPassword }    = useUserApi()
const { decodeUrlSafe, encodeUrlSafe } = useBase64()

const email = computed(() => store.state.user?.email || '')

const isLoading         = ref(false)
const showDeleteModal   = ref(false)
const passkeyToDelete   = ref(null)

const isPasskeySupported   = ref(false)
const currentPassword      = ref('')
const passkeyName          = ref('')
const passkeys             = ref([])

const registerState = ref({
    verifyPassword: false,
    name: '',
    // 0: 未开始, 1: 验证密码, 2: 请求options, 3: 返回密钥数据
    stage: 0,
    token: '',
})

onMounted(() => {
    if (!store.state.user) {
        store.dispatch('getUserData')
    }

    // 检查浏览器是否支持 Passkey
    isPasskeySupported.value = window.PublicKeyCredential && 
        window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable;
    
    // 获取已注册的通行密钥列表
    getPasskeys();
})

// 获取通行密钥列表
const getPasskeys = async () => {
    isLoading.value = true;
    try {
        const res = await axios.get(getApiURL('passkeys'));
        passkeys.value = res.data;
    } catch (err) {
        console.error('获取通行密钥列表失败:', err);
        showToast('错误', '获取通行密钥列表失败', 'error');
    } finally {
        isLoading.value = false;
    }
}

// 删除通行密钥
const deletePasskey = async (id) => {
    passkeyToDelete.value = id;
    showDeleteModal.value = true;
}

// 确认删除通行密钥
const confirmDeletePasskey = async () => {
    if (!passkeyToDelete.value) return;
    
    let url = getApiURL('passkeys')
    url += `/${passkeyToDelete.value}`

    try {
        await axios.delete(url);
        showToast('操作成功', '通行密钥已删除', 'success');
        await getPasskeys(); // 刷新列表
    } catch (err) {
        console.error('删除通行密钥失败:', err);
        showToast('错误', '删除通行密钥失败', 'error');
    } finally {
        showDeleteModal.value = false;
        passkeyToDelete.value = null;
    }
}

const verify = async () => {
    isLoading.value = true;
    try {
        const res = await verifyPassword(currentPassword.value);
        registerState.value.stage = 2;
        registerState.value.token = res.token;
        currentPassword.value = '';

        showToast('操作成功', '密码验证成功', 'success');
    } catch (err) {
        console.error(err);
    } finally {
        isLoading.value = false;
    }
}

const registerPasskey = async () => {
    if (!isPasskeySupported.value) {
        showToast('错误', '您的浏览器不支持 Passkey', 'error');
        return;
    }

    isLoading.value = true;
    
    try {
        // 获取注册选项
        const res = await axios.get(getApiURL('passkey'));

        const options = res.data;

        try {
            options.challenge = decodeUrlSafe(options.challenge).buffer;
            options.user.id   = decodeUrlSafe(options.user.id).buffer;
            
            if (options.excludeCredentials) {
                options.excludeCredentials = options.excludeCredentials.map(cred => ({
                    ...cred,
                    id: decodeUrlSafe(cred.id).buffer
                }));
            }
        } catch (e) {
            console.error('Data conversion error:', e);
            showToast('错误', '数据格式错误', 'error');
            return;
        }

        // 创建凭证
        const credential = await navigator.credentials.create({
            publicKey: options
        });

        await axios.post(getApiURL('passkey'), {
            name:       passkeyName.value,
            id:         credential.id,
            type:       credential.type,
            rawId:      encodeUrlSafe(credential.rawId),
            response: {
                clientDataJSON: encodeUrlSafe(credential.response.clientDataJSON),
                attestationObject: encodeUrlSafe(credential.response.attestationObject),
                transports: credential.response.getTransports?.() || [],
            },
        }, {
            headers: {
                'X-Password-Token': registerState.value.token
            }
        });

        showToast('操作成功', 'Passkey 注册成功', 'success');
        currentPassword.value = '';
        passkeyName.value = '';

        await getPasskeys(); // 刷新密钥列表
    } catch(err) {
        console.error(err);
        if (err.name === 'NotAllowedError') {
            showToast('错误', '注册被取消', 'error');
        }
    } finally { 
        isLoading.value = false;
    }
}
</script>

<template>
    <div class="card shadow-sm" v-if="isPasskeySupported">
        <div class="card-header bg-white border-bottom p-3 px-4">
            <div class="d-flex align-items-center">
                <span class="fw-bold">通行密钥设置</span>
            </div>
        </div>

        <div class="card-body p-4">
            <!-- 介绍部分 -->
            <div class="mb-4 bg-light rounded-3 p-3">
                <h6 class="text-sm fw-bold text-dark mb-3">
                    <i class="fas fa-info-circle text-primary me-2"></i>
                    什么是通行密钥？
                </h6>
                <p class="text-sm text-secondary mb-0">
                    通行密钥是一种更安全的登录方式，它使用生物识别（如指纹、面容）或设备 PIN 码来验证您的身份。
                    相比传统密码，通行密钥更安全、更方便，且不会被钓鱼网站窃取。
                </p>
            </div>

            <!-- 已注册密钥列表 -->
            <div class="mb-4">
                <h6 class="text-sm fw-bold text-dark mb-3">
                    <i class="fas fa-list text-primary me-2"></i>
                    已注册的通行密钥
                </h6>
                <div v-if="isLoading && registerState.stage === 0" class="text-center py-4">
                    <div class="spinner-border spinner-border-sm text-primary" role="status">
                        <span class="visually-hidden">加载中...</span>
                    </div>
                </div>
                <div v-else-if="passkeys.length === 0" class="text-center py-4 bg-light rounded-3">
                    <i class="fas fa-key text-secondary mb-2" style="font-size: 1.5rem;"></i>
                    <p class="text-secondary mb-0">暂无已注册的通行密钥</p>
                </div>
                <div v-else class="list-group list-group-flush">
                    <div v-for="passkey in passkeys" :key="passkey.id" 
                        class="list-group-item list-group-item-action d-flex justify-content-between align-items-center py-3">
                        <div>
                            <h6 class="mb-1 d-flex align-items-center">
                                <i class="fas fa-key text-primary me-2"></i>
                                {{ passkey.name }}
                            </h6>
                            <small class="text-secondary">
                                <i class="far fa-clock me-1"></i>
                                创建于 {{ new Date(passkey.created_at).toLocaleString() }}
                            </small>
                        </div>
                        <button type="button" class="btn btn-sm btn-outline-danger" 
                            @click="deletePasskey(passkey.id)">
                            <i class="fas fa-trash-alt me-1"></i>
                            删除
                        </button>
                    </div>
                </div>
            </div>

            <!-- 注册新密钥部分 -->
            <div class="d-flex align-items-center mb-4 bg-light rounded-3 p-3" v-if="registerState.stage === 0">
                <div class="flex-grow-1">
                    <h6 class="text-sm fw-bold text-dark mb-1">
                        <i class="fas fa-plus-circle text-primary me-2"></i>
                        注册新通行密钥
                    </h6>
                    <p class="text-sm text-secondary mb-0">
                        注册后，您可以使用设备的安全验证方式（如指纹、面容）快速登录
                    </p>
                </div>
                <button type="button" class="btn btn-primary mb-0" 
                    @click="registerState.stage = 1">
                    <i class="fas fa-plus me-2"></i>
                    注册
                </button>
            </div>

            <!-- 密码验证表单 -->
            <div v-if="registerState.stage === 1" class="card border-0 bg-light">
                <div class="card-body p-3 pb-0">
                    <div class="d-flex align-items-center mb-4">
                        <div class="icon-box me-3">
                            <i class="fas fa-lock text-primary"></i>
                        </div>
                        <div>
                            <h6 class="mb-1 fw-bold text-dark">密码验证</h6>
                            <p class="text-sm text-secondary mb-0">请输入您的当前密码以验证身份</p>
                        </div>
                    </div>
                    
                    <form>
                        <div class="form-group mb-4 d-none">
                            <label class="d-block text-sm">邮箱</label>
                            <input type="email" class="form-control" v-model="email" autocomplete="email" aria-hidden="true" />
                        </div>

                        <div class="form-group mb-4">
                            <label class="d-block text-sm fw-bold mb-2">当前密码</label>
                            <div class="input-group">
                                <span class="input-group-text bg-white">
                                    <i class="fas fa-lock text-secondary"></i>
                                </span>
                                <input type="password" class="form-control" 
                                    aria-label="当前密码"
                                    autocomplete="current-password"
                                    placeholder="请输入当前密码以验证身份"
                                    v-model="currentPassword"/>
                            </div>
                        </div>

                        <div class="d-flex justify-content-end gap-2">
                            <button type="button" class="btn btn-outline-secondary" 
                                @click="registerState.stage = 0">
                                <i class="fas fa-times me-2"></i>
                                取消
                            </button>
                            <button type="button" class="btn btn-primary" 
                                :disabled="isLoading"
                                @click="verify">
                                <span v-if="isLoading" class="spinner-border spinner-border-sm me-2"></span>
                                <i v-else class="fas fa-check me-2"></i>
                                验证密码
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <!-- 注册表单 -->
            <div v-if="registerState.stage === 2" class="card border-0 bg-light">
                <div class="card-body p-3 pb-0">
                    <div class="d-flex align-items-center mb-4">
                        <div class="icon-box me-3">
                            <i class="fas fa-key text-primary"></i>
                        </div>
                        <div>
                            <h6 class="mb-1 fw-bold text-dark">注册通行密钥</h6>
                            <p class="text-sm text-secondary mb-0">为您的通行密钥设置一个名称，方便识别</p>
                        </div>
                    </div>
                    
                    <form>
                        <div class="form-group mb-4">
                            <label class="d-block text-sm fw-bold mb-2">通行密钥名称</label>
                            <div class="input-group">
                                <span class="input-group-text bg-white">
                                    <i class="fas fa-tag text-secondary"></i>
                                </span>
                                <input type="text" class="form-control" v-model="passkeyName" autocomplete="off"
                                    placeholder="为您的通行密钥起个名字（可选）" />
                            </div>
                            <small class="text-secondary mt-2 d-block">
                                <i class="fas fa-info-circle me-1"></i>
                                如果不填写，将使用默认名称"未命名密钥"
                            </small>
                        </div>

                        <div class="alert alert-info d-flex align-items-center mb-4">
                            <i class="fas fa-info-circle me-2 text-primary"></i>
                            <div class="text-sm">
                                点击"确认注册"后，系统将请求您使用设备的安全验证方式（如指纹、面容）进行验证。
                            </div>
                        </div>

                        <div class="d-flex justify-content-end gap-2">
                            <button type="button" class="btn btn-outline-secondary" 
                                @click="registerState.stage = 0">
                                <i class="fas fa-times me-2"></i>
                                取消
                            </button>
                            <button type="button" class="btn btn-primary" 
                                :disabled="isLoading"
                                @click="registerPasskey">
                                <span v-if="isLoading" class="spinner-border spinner-border-sm me-2"></span>
                                <i v-else class="fas fa-plus me-2"></i>
                                确认注册
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <!-- 使用说明 -->
            <div class="mt-4 pt-3 border-top">
                <h6 class="text-sm fw-bold text-dark mb-3">
                    <i class="fas fa-book text-primary me-2"></i>
                    使用说明
                </h6>
                <ul class="list-unstyled mb-0">
                    <li class="mb-2 d-flex align-items-start">
                        <i class="fas fa-check-circle text-success mt-1 me-2"></i>
                        <span class="text-sm text-secondary">注册通行密钥需要先输入当前密码进行验证</span>
                    </li>
                    <li class="mb-2 d-flex align-items-start">
                        <i class="fas fa-check-circle text-success mt-1 me-2"></i>
                        <span class="text-sm text-secondary">可以为通行密钥设置一个名称，方便识别不同设备</span>
                    </li>
                    <li class="d-flex align-items-start">
                        <i class="fas fa-check-circle text-success mt-1 me-2"></i>
                        <span class="text-sm text-secondary">如果不再使用请删除对应的通行密钥</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <!-- 删除确认模态框 -->
    <newModal
        v-model="showDeleteModal"
        title="删除确认"
        size="md"
        :close-text="'取消'"
    >
        <div class="text-center py-3">
            <i class="fas fa-exclamation-triangle text-warning mb-3" style="font-size: 2rem;"></i>
            <p class="mb-0">确定要删除这个通行密钥吗？此操作不可恢复。</p>
        </div>
        <template #buttons>
            <button 
                class="btn btn-danger mb-0 me-2" 
                @click="confirmDeletePasskey"
            >
                <i class="fas fa-trash-alt me-2"></i>
                确定删除
            </button>
        </template>
    </newModal>
</template>

<style scoped>
.tips-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.tips-list li {
    position: relative;
    padding-left: 1.25rem;
    font-size: 0.8125rem;
    color: #67748e;
    margin-bottom: 0.5rem;
}

.tips-list li:last-child {
    margin-bottom: 0;
}

.tips-list li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0.5rem;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #67748e;
}

.list-group-item {
    border-left: none;
    border-right: none;
}

.list-group-item:first-child {
    border-top: none;
}

.list-group-item:last-child {
    border-bottom: none;
}
</style> 