export function useBase64() {
    const decode = (s) => {
        try {
            // 确保输入是有效的 base64 字符串
            if (!s || typeof s !== 'string') {
                throw new Error('Invalid base64 input');
            }
            // 移除可能的 URL 安全字符
            const base64 = s.replace(/-/g, '+').replace(/_/g, '/');
            // 添加必要的填充
            const padded = base64.padEnd(base64.length + (4 - (base64.length % 4)) % 4, '=');
            return Uint8Array.from(atob(padded), c => c.charCodeAt(0));
        } catch (e) {
            console.error('Base64 decode error:', e, 'Input:', s);
            throw e;
        }
    }

    const encode = (b) => {
        return btoa(String.fromCharCode(...new Uint8Array(b)));
    }

    const encodeUrlSafe = (b) => {
        const base64 = encode(b);
        return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
    }

    const decodeUrlSafe = (s) => {
        try {
            // 确保输入是有效的 base64 字符串
            if (!s || typeof s !== 'string') {
                throw new Error('Invalid base64 input');
            }
            // 添加回填充
            const padded = s.padEnd(s.length + (4 - (s.length % 4)) % 4, '=');
            // 转换回标准 base64
            const base64 = padded.replace(/-/g, '+').replace(/_/g, '/');
            return Uint8Array.from(atob(base64), c => c.charCodeAt(0));
        } catch (e) {
            console.error('Base64UrlSafe decode error:', e, 'Input:', s);
            throw e;
        }
    }

    const encodeStringUrlSafe = (s) => {
        return encodeUrlSafe(new TextEncoder().encode(s));
    }

    const decodeStringUrlSafe = (s) => {
        return new TextDecoder().decode(decodeUrlSafe(s));
    }


    return { 
        decode,
        encode,
        encodeUrlSafe,
        decodeUrlSafe,
        encodeStringUrlSafe,
        decodeStringUrlSafe,
    };
} 