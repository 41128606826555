export function useApiURL() {
    // * 定义 Endpoint
    let endpoint;
    if (process.env.NODE_ENV === 'development') {
        endpoint = 'http://localhost';
    } else {
        endpoint = 'https://api.coolti.net';
    }

    const getApiURL = (key) => {
        let URLs = {
            // * Auth - 授权相关
            'sendVerifyCode':       '/api/sendVerifyCode',
            'verifyQuestion':       '/api/verifyQuestion',
            'verifyPassword':       '/api/user/verifyPassword',

			'getBingImage':         '/api/fetchBingImage',
			'token':		        '/api/validLoginToken',
			'login':		        '/api/login',
			'logout':		        '/api/logout',
            'register':		        '/api/register',

            'forgotPassword':       '/api/forgotPassword',
            'resetPassword':        '/api/resetPassword',

            // * Passkey
            'passkeys':             '/api/setting/passkeys',
            'passkey.login':        '/api/login/passkey',
            'passkey':              '/api/setting/passkey',

            // * Settings
            '2FA':                  '/api/setting/2fa',
            'resetConnPass':        '/api/setting/resetConnPwd',
            'updatePassword':       '/api/setting/password',
            'updateUserName':       '/api/setting/username',
            'telegram':             '/api/user/telegram',

            // * Referral
            'referral':             '/api/referral',
            'convertRefMoney':      '/api/referral/convert',
            'createDiscount':       '/api/referral/discount',

			'getUserData':	        '/api/user',
			'getAnnData':	        '/api/ann',
			'getSubData':	        '/api/sub',
            'getClientData':        '/api/user/getClient',
			'getIOSData':           '/api/user/iosProfile',
            'getLogsData':          '/api/user/logs',
            'getOnlineCount':       '/api/user/onlineCount',

            'getLogData':           '/api/get-detail',
            'getSaleData':          '/api/getSaleData',
            'getShopData':          '/api/shop',
            'purchase':             '/api/shop/purchase',
            'recharge':             '/api/shop/recharge',

            'checkin':              '/api/user/checkin',
            'updateLoginPass':      '/api/user/password',

            'shop':                 '/api/shop',
            'order':                '/api/order',
            'nodes':                '/api/nodes',
            'ticket':               '/api/ticket',
            'plan':                 '/api/plan',

            'giftCode':             '/api/shop/giftCode',
            'subToken':             '/api/sub',
        };

        if (!key) {
            // add endpoint to all URLs
            for (let key in URLs) {
                URLs[key] = `${endpoint}${URLs[key]}`;
            }

            return URLs;
        }

        const url = URLs[key];
        if (!url) {
			console.log(`API Key ${key} is not defined`);
            throw new Error(`API Key ${key} is not defined`);
        }

        const fullURL = `${endpoint}${url}`;

        return fullURL;
    }

    return { getApiURL }
}