<script setup>
import { ref, computed, onMounted }          from 'vue'
import { useStore }     from 'vuex'
import { useShowToast } from '@/composables/useShowToast'
import axios            from '@/Plugins/axios'
import { useApiURL }    from '@/composables/useApiURL'

const store         = useStore()
const { showToast } = useShowToast()
const { getApiURL } = useApiURL()

const email = computed(() => store.state.user?.email || '')
const passwordForm = ref({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
})

const isLoading = ref(false)

onMounted(() => {
    if (!store.state.user) {
        store.dispatch('getUserData')
    }
})

const handlePasswordChange = async () => {
    if (passwordForm.value.newPassword !== passwordForm.value.confirmPassword) {
        showToast('两次输入的密码不一致', 'warning')
        return
    }

    // check is the old password and the new password is the same
    if (passwordForm.value.oldPassword === passwordForm.value.newPassword) {
        showToast('新密码不能与旧密码相同', 'warning')
        return
    }
    
    isLoading.value = true
    try {
        await axios.put(getApiURL('updatePassword'), {
            current_password: passwordForm.value.oldPassword,
            password: passwordForm.value.newPassword,
            password_confirmation: passwordForm.value.confirmPassword
        })
        
        showToast('操作成功', '密码修改成功', 'success')
        passwordForm.value = { oldPassword: '', newPassword: '', confirmPassword: '' }
        
        store.commit('clearToken')
        setTimeout(() => {
            router.push({ name: '登录' })
        }, 1500)
    } catch (error) {
        console.error(error)
    } finally {
        isLoading.value = false
    }
}
</script>

<template>
    <div class="card">
        <div class="card-header border-bottom p-3 px-4 text-dark">
            <div class="d-flex align-items-center">
                <i class="ni ni-lock-circle-open me-2"></i>
                <span>密码设置</span>
            </div>
        </div>

        <div class="card-body">
            <form @submit.prevent="handlePasswordChange">
                <div class="form-group d-none">
                    <label class="d-block text-sm">邮箱</label>
                    <input type="email" autocomplete="email" class="form-control" v-model="email" disabled />
                </div>

                <div class="form-group">
                    <label class="d-block text-sm">当前密码</label>
                    <input type="password" autocomplete="current-password" class="form-control" v-model="passwordForm.oldPassword" required />
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="d-block text-sm">新密码</label>
                            <input type="password" class="form-control" autocomplete="new-password" v-model="passwordForm.newPassword" required />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="d-block text-sm">确认新密码</label>
                            <input  type="password" class="form-control"  autocomplete="new-password" v-model="passwordForm.confirmPassword" required />
                        </div>
                    </div>
                </div>

                <div class="mt-md-3 pt-md-3 mt-2 pt-2 border-top">
                    <h6 class="text-sm fw-bold text-dark" style="margin-bottom: 0.75rem;">相关提示</h6>
                    <ul class="tips-list">
                        <li>密码修改成功后需要重新登录</li>
                        <li>请使用包含字母和数字的组合提高密码强度</li>
                    </ul>
                </div>

                <button type="submit" class="btn btn-primary w-100 mt-4" :disabled="isLoading">
                    <span v-if="isLoading" class="spinner-border spinner-border-sm me-2"></span>
                    确认修改
                </button>
            </form>
        </div>
    </div>
</template>

<style scoped>
i {
    font-size: 1.125rem;
    color: var(--bs-primary);
}

.tips-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.tips-list li {
    position: relative;
    padding-left: 1.25rem;
    font-size: 0.8125rem;
    color: #67748e;
    margin-bottom: 0.5rem;
}

.tips-list li:last-child {
    margin-bottom: 0;
}

.tips-list li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0.5rem;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #67748e;
}
</style> 