import axios            from '@/Plugins/axios';
import { useCache }     from "./useCache";
import { useShowToast } from "./useShowToast";
import { useApiURL }    from "./useApiURL";

const { getApiURL }            = useApiURL();
const { showToast }            = useShowToast();
const { storeCache, getCache } = useCache();

export function useUserApi() {
    const getUserData = async (useCache = true) => {
        // * 检查缓存
        if (useCache) {
            const cacheData = getCache('user');
            if (cacheData) return cacheData;
        }

        return await axios.get(getApiURL('getUserData'))
            .then(res => {
                // * 存入缓存
                storeCache('user', res.data, 60, 'session');

                return res.data;
            })
            .catch(err => { errorHandle(err); });
    }

    const getShopData = async (useCache = true) => {
        // * 检查缓存
        if (useCache) {
            const cacheData = getCache('shop');
            if (cacheData) return cacheData;
        }

        return await axios.get(getApiURL('getShopData'))
            .then(res => {
                // * 存入缓存
                if (res.data?.forceRefresh !== true) {
                    storeCache('shop', res.data, 60 * 60, 'session');
                }

                return res.data;
            })
            .catch(err => {  errorHandle(err); });
    }

    const getSubData = async (useCache = true) => {
        // * 检查缓存
        if (useCache) {
            const cacheData = getCache('subscribe');
            if (cacheData) return cacheData;
        }

        return await axios.get(getApiURL('getSubData'))
            .then(res => {
                // * 存入缓存
                storeCache('subscribe', res.data, 5 * 60, 'session');

                return res.data;
            })
            .catch(err => { errorHandle(err); });
    }

    const getClientData = async (useCache = true) => {
        // * 检查缓存
        if (useCache) {
            const cacheData = getCache('client');
            if (cacheData) return cacheData;
        }

        return await axios.get(getApiURL('getClientData'))
            .then(res => {
                // * 存入缓存
                storeCache('client', res.data, 5 * 60, 'session');

                return res.data;
            })
            .catch(err => { errorHandle(err); });
    }

    const getDetailData = async (id, type) => {
        const typeName = { invoice: '账单', order:   '订单' };

        return await axios.get(getApiURL('getLogData'), {
            params: { type: type, id: id, }
        })
        .then(res => {
            if (res.data.status !== 'success') {
                showToast('error', `获取${typeName[type]}信息失败, ${res.data.message}`, 'warning')
                throw new Error(`获取${typeName[type]}信息失败-${JSON.stringify(res?.data)}`);
            }

            return res.data;
        })
        .catch(err => { errorHandle(err); });
    }

    const getTicketData = async (id) => {
        return await axios.get(`${getApiURL('ticket')}/${id}`)
        .then(res => {
            // * 转换时间格式
            res.data.data.created_at = new Date(res.data.data.created_at).toLocaleString('zh-CN', { hour12: false });
    
            return res.data;
        })
        .catch(err => { errorHandle(err); });
    }

    const getTicketsData = async (useCache = true) => {
        // * 检查缓存
        if (useCache) {
            const cacheData = getCache('tickets');
            if (cacheData) return cacheData;
        }

        return await axios.get(getApiURL('ticket'))
        .then(res => {
            storeCache('tickets', res.data, 30 * 60, 'session');

            return res.data;
        })
        .catch(err => {  errorHandle(err); });
    }

    const getMiscData = async (type, useCache = true) => {
        // * 检查缓存
        if (useCache) {
            const cacheData = getCache(`miscData-${type}`);
            if (cacheData) return cacheData;
        }

        return await axios.get(getApiURL('getLogsData'), {
            params: { type: type }
        })
        .then(res => {
            storeCache(`miscData-${type}`, res.data, 5 * 60, 'session');
    
            return res.data;
        })
        .catch(err => { errorHandle(err); });
    }

    const getAnn = async (type, useCache = true) => {
        type = Number(type);
        if (!type && type !== 0) return;

        // * 检查缓存
        if (useCache) {
            const cacheData = getCache(`ann-${type}`);
            if (cacheData) return cacheData;
        }

        return await axios.get(
            getApiURL('getAnnData'),
            { params: { type: type } }
        ).then(res => {
            if (res.data.data.forceRefresh === false) {
                storeCache(`ann-${type}`, res.data, 5 * 60, 'session');
            }
    
            return res.data;
        })
        .catch(err => { errorHandle(err); });
    }

    const verifyPassword = async (password) => {
        return await axios.post(getApiURL('verifyPassword'), { password: password })
        .then(res => {
            return res.data;
        })
        .catch(err => { errorHandle(err); });
    }

    const errorHandle = (err) => {
        if (err.response?.status === 401) {
            err.message = '401 Unauthorized';
        }

        throw new Error(err);
    }

    return {
        getUserData,
        getShopData,
        getSubData,
        getClientData,
        getDetailData,
        getTicketData,
        getTicketsData,
        getMiscData,
        getAnn,
        verifyPassword,
    }
}