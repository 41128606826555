<script setup>
import { computed, ref } from 'vue'
import axios from '@/Plugins/axios'
import { useApiURL } from '@/composables/useApiURL'

const props = defineProps({
    user: {
        type: Object,
        required: true
    }
})

const { getApiURL } = useApiURL()

const showDetails = ref(false)
const onlineCount = ref(0)
const timeCount   = ref(0)

const trafficPercent = computed(() => {
    return Math.round((props.user.traffic.remain / props.user.traffic.total) * 100)
})

const usedTraffic = computed(() => {
    return Number(props.user.traffic.total - props.user.traffic.remain).toFixed(2)
})

const daysUntilReset = computed(() => {
    const today = new Date()
    const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1)
    const diff = nextMonth - today
    return Math.ceil(diff / (1000 * 60 * 60 * 24))
})

const daysUntilExpire = computed(() => {
    const today = new Date()
    const expire = new Date(props.user.active_plan.expired_at)
    const diff = expire - today
    return Math.ceil(diff / (1000 * 60 * 60 * 24))
})

const speedLimit = computed(() => {
    return props.user.node_speedlimit == 0 ? '无限制' : props.user.node_speedlimit + ' M'
})

const getOnlineCount = async (event) => {
    if (event) event.target.disabled = true
    
    try {
        const res = await axios.get(getApiURL('getOnlineCount'))
        onlineCount.value = res.data.online

        // 开始30秒倒计时
        timeCount.value = 30
        const timer = setInterval(() => {
            timeCount.value--
            if (timeCount.value === 0) {
                clearInterval(timer)
            }
        }, 1000)
    } catch (err) {
        console.error(err)
    } finally {
        if (event) event.target.disabled = false
    }
}
</script>

<template>
    <div class="card">
        <div class="card-body p-3" v-if="user.active_plan">
            <div class="row">
                <div class="col-8">
                    <h6 class="text-uppercase text-body text-xs font-weight-bolder mb-3">套餐信息</h6>
                </div>
                <div class="col-4 text-end">
                    <button class="btn btn-sm btn-outline-primary mb-0" @click="showDetails = !showDetails">
                        {{ showDetails ? '收起' : '详情' }}
                    </button>
                </div>
            </div>
            
            <!-- 基础信息 -->
            <div class="progress-wrapper">
                <div class="progress-info">
                    <div class="progress-percentage">
                        <span class="text-sm font-weight-bold">已用流量: {{ usedTraffic }} / {{ user.traffic.total }} G</span>
                    </div>
                </div>
                <div class="progress">
                    <div class="progress-bar bg-gradient-info" :style="`width: ${trafficPercent}%`"
                        role="progressbar" :aria-valuenow="trafficPercent" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-4">
                    <p class="text-xs mb-1">剩余流量</p>
                    <h6 class="font-weight-bolder mb-0">{{ user.traffic.remain }} G</h6>
                </div>
                <div class="col-4">
                    <p class="text-xs mb-1">重置日期</p>
                    <h6 class="font-weight-bolder mb-0">{{ daysUntilReset }}天后</h6>
                </div>
                <div class="col-4">
                    <p class="text-xs mb-1">到期时间</p>
                    <h6 class="font-weight-bolder mb-0">{{ daysUntilExpire }}天后</h6>
                </div>
            </div>

            <!-- 详细信息 -->
            <div class="mt-4" v-if="showDetails">
                <hr class="horizontal dark my-3">
                <div class="row g-3">
                    <div class="col-6">
                        <div class="info-card">
                            <p class="text-xs mb-1">今日已用</p>
                            <h6 class="font-weight-bolder mb-0">{{ user.traffic.daily.toFixed(2) }} G</h6>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="info-card">
                            <p class="text-xs mb-1">本月已用</p>
                            <h6 class="font-weight-bolder mb-0">{{ user.traffic.monthly.toFixed(2) }} G</h6>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="info-card">
                            <p class="text-xs mb-1">年度已用</p>
                            <h6 class="font-weight-bolder mb-0">{{ user.traffic.yearly.toFixed(2) }} G</h6>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="info-card">
                            <p class="text-xs mb-1">当前限速</p>
                            <h6 class="font-weight-bolder mb-0">{{ speedLimit }}</h6>
                        </div>
                    </div>
                    <!-- <div class="col-6">
                        <div class="info-card h-100">
                            <p class="text-xs mb-1">最大在线</p>
                            <h6 class="font-weight-bolder mb-0">{{ user.node_connector }} 个</h6>
                        </div>
                    </div> -->
                    <div class="col-12">
                        <div class="info-card">
                            <p class="text-xs mb-1">当前在线</p>
                            <div class="d-flex align-items-center">
                                <template v-if="timeCount !== 0">
                                    <i class="ni ni-sound-wave text-secondary me-2"></i>
                                    <h6 class="font-weight-bolder mb-0">{{ onlineCount }} 人</h6>
                                </template>
                                <button v-else 
                                    class="btn btn-xs btn-outline-primary py-1 px-2 mb-0"
                                    @click="getOnlineCount">
                                    点击查询
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 过期视图 -->
        <div class="card-body p-3" v-else>
            <div class="row">
                <div class="col-12">
                    <h6 class="text-uppercase text-body text-xs font-weight-bolder mb-3">套餐信息</h6>
                </div>
            </div>

            <div class="text-center py-4">
                <div class="icon icon-shape bg-gradient-warning shadow-warning text-center rounded-circle mb-3">
                    <i class="fa-solid fa-hand text-lg opacity-10" aria-hidden="true"></i>
                </div>
                <h6 class="text-warning mb-2">当前无可用套餐</h6>
                <p class="text-secondary text-sm mb-3">
                    账户当前没有任何可用的套餐，请购买新套餐以继续使用服务。
                </p>
                <router-link :to="{ name: '套餐中心' }" class="btn btn-sm bg-gradient-warning mb-0">
                    <i class="fas fa-shopping-cart me-1"></i>
                    购买套餐
                </router-link>
            </div>
        </div>
    </div>
</template>

<style scoped>
.info-card {
    padding: 0.75rem;
    border-radius: 0.5rem;
    background: #f8f9fa;
}

.info-card .mb-0 {
    font-size: 0.875rem;
}
</style>